@import url('https://fonts.googleapis.com/css?family=Playfair+Display:400,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:700&display=swap');

/* font-family: 'Montserrat', sans-serif; */

body {
    margin: 0;
    font-family: 'Playfair Display', serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #eeeeee !important;
    visibility: hidden;

}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}