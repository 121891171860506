@import './globals.scss';

.Hero-hello-col {
  h1 {
    font-size: 4rem;

    @media screen and (max-width: 576px) {
      font-size: 3rem;
    }
  }
}

.Hero {
  padding-top: 100px;
  margin-bottom: 100px;
  width: 100%;

  .Hero-row {
    color: #1e1e1e;
    font-family: "Montserrat", sans-serif;
    margin: 0 auto;
    color: $darktext;
    margin-bottom: 200px;
  }
}

.Contact-button-wrapper {
  display: flex;
  padding-top: 25px;
  // justify-content: center;
  // align-items: center;
  margin: auto;
  .Contact-button {
    display: flex;
    flex-direction: row;
    width: 120px;
    height: 50px;
    background-color: $light;
    border-radius: 50px;
    box-shadow: 6px 6px 16px #d1cdd780, -6px -6px 16px #ffffff80;
    overflow: hidden;
    border: none;
    background: #C04848;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #480048, #C04848);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #480048, #C04848);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
    color: white;
    font-size: 1rem;
    transition: 0.15s color ease-in-out;

    &:hover {
      color: white;

      .Contact-button-arrow {
        .Arrow-body {
          width: 20px;
          border: 0.5px solid white;
        }

        .Arrow-triangle {
          border-left: 5px solid white;
        }
      }
    }

    .Contact-button-text {
      height: 50px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-left: 10px;

      p {
        margin: 0;
      }
      text-decoration: none !important;
      font-family: "Montserrat", "sans-serif";
    }

    .Contact-button-arrow {
      height: 50px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: left;
      padding-left: 5px;
      align-items: center;

      .Arrow-body {
        display: block;
        height: 1px;
        width: 10px;
        border: white 0.5px solid;
        transition: 0.3s width ease-in-out;
      }

      .Arrow-triangle {
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-left: 5px solid white;
      }
    }
  }
}

.add-text {
  width: 300px;
  margin: 0 auto;
  text-align: center;
  background-color: $light;
  box-shadow: $shadow-property;
  font-family: "Poppins", sans-serif;
  padding: 10px 5px;
  border-radius: 30px;
}

.chevron-box {
  margin: 0 auto;
  box-shadow: 2px 2px 2px #8b8b8b, -2px -2px 2px #ffffff;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background-color: $darktext;

  svg {
    transform: translateY(5px);
    color: $light;
  }
}